<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="shareColor"
                v-model="share")
                    | {{ shareText }}
                    v-btn(flat, dark, @click.native="share = false") {{$t('cerrar')}}
                v-container.grid-list-xl.share(fluid)
                    .share-content.text-xs-center
                        h3 {{ sharedata.text }}
                        a.primary--text.code--text(style="font-size:40px !important;" :href="sharedata.url" target="_blank") {{ sharedata.url }}
                        qrcode-vue.share-qr(:value="sharedata.url" :size="300" level="H")
                        .btns.btns-center
                            v-btn.btn-whatsapp(@click.native="shareWp" large color="secondary light--text") 
                                img(src="/public/img/icons/whatsapp-icon.svg" alt="Whatsapp")
                                | {{$t('Compartir en whatsapp')}}
                            v-btn.btn-facebook(@click.native="shareFb" small color="secondary light--text") 
                                img(src="/public/img/icons/facebook-icon.svg" alt="Facebook")
                                | {{$t('Compartir en facebook')}}
                            v-btn.btn-twitter(@click.native="shareTw" small color="secondary light--text") 
                                img(src="/public/img/icons/twitter-icon.svg" alt="Twitter")
                                | {{$t('Compartir en twitter')}}
                        v-divider.mt-5
                        h4.primary--text {{$t('Ó POR CORREO ELECTRÓNICO')}}
                        v-form
                            v-text-field.medium.text-center(v-model="email", :label="$t('Correo electrónico')", type="email", required)
                            v-btn(@click.native="shareEmail" large color="primary" :disabled="loadDisabled") {{ loadState }}
            wp-confirm
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import QrcodeVue from 'qrcode.vue'

    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Compartir')
            }
        },
        data(){
            return {
                share     : false,
                shareColor: 'error',
                shareText : '',
                email     : '',
                defaultText: 'Te invito al reto de construir una COLOMBIA FUERTE Entre todos lo lograremos, únete',
                loadOptions: {
                    normal: 'Enviar',
                    load: 'Enviando...'
                },
                loadDisabled: false,
                loadState: '',
                sharedata: {
                    encode: '',
                    text  : '',
                    url   : ''
                }
            }
        },
        created(){
            this.loadState = this.loadOptions.normal
            this.fetchShareUrl()
        },
        computed: {

            profile(){
                return this.$root.profile
            },

            networkText(){
                return this.profile.network.text
            },

            inviteURL(){
                return this.profile.networkUrl
            },
            
            networkURL(){
                return window.encodeURIComponent(this.networkText + ' ' + this.inviteURL)
            },

            url(){
                return config.url
            },

            logoUrl(){
                return config.url + 'public/img/photos/fuertes-register.png'
            }
        },
        methods: {

            fetchShareUrl() {

                this.$root.loader = true
                this.$api(this, (xhr) => {
                    xhr.get('/settings/shareurl').then((r) => {

                        let data = r.data
                        this.$root.loader = false
                        
                        if (data.response) {
                            this.sharedata = data.data
                        }
                    }).catch(() => {})
                })
            },

            shareEmail(){

                this.loadState = this.loadOptions.load
                this.loadDisabled = true

                this.$api(this, (xhr) => {
                    xhr.post('/share/email', this.$qs.stringify({email: this.email})).then((r) => {
                        
                        let data = r.data
                        this.share = true
                        this.loadState = this.loadOptions.normal
                        this.loadDisabled = false

                        if(data.response){
                            this.shareColor = 'success'
                            this.shareText = data.message
                            this.email = ''
                        }else{
                            this.shareColor = 'error'
                            this.shareText = data.message
                        }
                    }).catch(() => {})
                })
            },

            shareFb(){
                window.open(
                    'https://www.facebook.com/sharer/sharer.php?u=' + window.encodeURIComponent(this.sharedata.url), 
                    'popup', 
                    'width=400, height=300'
                )
            },
            shareTw(){
                window.open(
                    'https://twitter.com/intent/tweet?text=' + this.sharedata.encode, 
                    'popup',
                    'width=400, height=300'
                )
            },
            shareWp(){
                let url = this.isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/'
                window.open(
                    url+'send?text=' + this.sharedata.encode,
                    'popup'
                )
            }
        },
        components: {
            QrcodeVue
        }
    }
</script>