import currentLang from 'scripts/lang'
import fn from 'mixins/fn'

export default {
    mixins: [fn],

    async beforeCreate() {

        this.$root.loader = true

        let routeMeta = this.$route.meta
        let routeName = this.$route.name
        let route = this.$route
        let currentNetwork = process.browser ? (window.NETWORK ? window.NETWORK : false) : false

        //Establecer idioma por defecto
        let lang = currentLang(this.$root.languages, this.$root.fallbackLanguage)
        this.$root.setLanguage(lang)
        
        if (routeMeta.hasOwnProperty('exclude') && routeMeta.exclude) {
            return this.$root.loader = false
        }

        // Landing admin
        if (routeName == 'landing' && currentNetwork) {
            await this.$nextTick()
            return this.$router.push(this.loginTo)
        }

        let preProfile = () => {

            let pretk   = this.$route.query.tk
            let network = this.$route.query.n

            if (pretk) {

                let post = {
                    pretk,
                    network
                }
                
                return new Promise((resolve) => {
                    return this.$api(this, (xhr) => {
                        return xhr.post('/pre-profile', this.$qs.stringify(post)).then(async (r) => {
                        
                            let data = r.data
                            this.$root.loader = false
                            
                            if (data.response) {
                                await this.$setItem('xtoken', data.data)
                            }

                            let route = this.$route
                            let query = Object.assign({}, route.query)
                            
                            delete query.tk
                            delete query.n
                            
                            this.$router.replace({
                                path  : route.path,
                                query : query
                            })

                            resolve()
                        }).catch(() => {})
                    })
                })
            }
        }

        await preProfile()
        return this.$api(this, (xhr) => {
            return xhr.post('/profile').then((r) => {

                let data = r.data
                
                if (!data.response) {

                    this.$root.loader = false

                    if (!routeMeta.auth) return
                    if (routeMeta.authOptional) return
                    
                    return this.$router.push(this.loginTo)

                } else {

                    if (routeMeta.noredirect) return

                    let profile = data.data
                    this.$root.profile = Object.assign({}, profile)

                    if (routeMeta.authOptional) {

                        this.$root.loader = false
                        return
                    }

                    if (!routeMeta.auth) {

                        this.$root.loader = false
                        return this.$router.push({ path: '/home' })
                    }

                    //Administrador
                    let issubadmin   = profile.type == 2 
                    let isadmin      = profile.type == 0
                    let iscallcenter = profile.type == 3
                    let istranslator = profile.type == 5
                    
                    if (issubadmin && !profile.billing.length) {

                        this.$root.loader = false
                        return this.$router.push({path:'/plan'})
                    }

                    if (issubadmin && !this.issubadmin) {
                        this.$root.loader = false
                        return this.$router.push({ path: '/dashboard' })
                    }

                    if (isadmin && !this.isadmin) {
                        this.$root.loader = false
                        return this.$router.push({ path: '/dashboard' })
                    }

                    if (iscallcenter && !this.iscallcenter) {

                        this.$root.loader = false
                        return this.$router.push({ path: '/callcenter' })
                    }

                    if (istranslator && !this.istranslator) {

                        this.$root.loader = false
                        return this.$router.push({ path: '/translate' })
                    }
                    
                    //Email
                    let isemail = profile.network != undefined && 'email' in profile.network && profile.network.email.indexOf(profile.email) != -1
                    let iscomment = profile.network != undefined && 'email_comment' in profile.network && profile.network.email_comment.indexOf(profile.email) != -1

                    // Compartido con comentarista
                    if ((isemail || iscomment) && (this.isemail && this.iscomment)) {
                        this.$root.loader = false
                        return
                    }

                    if (!this.isadmin && !isemail && this.isemail) {

                        this.$root.loader = false
                        return this.$router.push({ path: '/home' })
                    }

                    if (!this.isadmin && !iscomment && this.iscomment) {

                        this.$root.loader = false
                        return this.$router.push({ path: '/home' })
                    }

                    if (!isadmin && !issubadmin && this.issubadmin) {

                        if (!this.isemail && !this.iscomment) {

                            this.$root.loader = false
                            return this.$router.push({ path: '/home' })
                        }
                    }

                    //Galería
                    this.isgallery = profile.network != undefined && 'gallery' in profile.network && profile.network.gallery.indexOf(profile.email) != -1

                    //Banners
                    this.isbanner = profile.network != undefined && 'banner' in profile.network && profile.network.banner.indexOf(profile.email) != -1

                    // Personalizar red
                    if(routeName == 'custom-network' && !profile.custom_network){
                        return this.$router.push({ path: '/home' })
                    }
                    
                    // Casas de registro
                    if (routeName == 'register-stand' && profile.hasOwnProperty('network') && !profile.network.standregister) {
                        return this.$router.push({ path: '/home' })
                    }

                    this.$root.loader = false
                }
            }).catch(() => {})
        })
    },

    created() {
        this.fetchConfig()
    },

    mounted() {
        this.$root.currentNetwork = this.currentNetwork = window.NETWORK ? window.NETWORK : false
    }
}